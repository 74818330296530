import { useStaticQuery, graphql } from "gatsby"

export const useHomepageQuery = () => {
  const data = useStaticQuery(graphql`
    query GetHomepagePhotos {
      sign: file(
        sourceInstanceName: { eq: "other" }
        relativePath: { eq: "add_lock_sign.JPG" }
      ) {
        id
        childImageSharp {
          fluid(maxHeight: 10000) {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      office: file(
        sourceInstanceName: { eq: "other" }
        relativePath: { eq: "add_lock_office.JPG" }
      ) {
        id
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      building: file(
        sourceInstanceName: { eq: "other" }
        relativePath: { eq: "add_lock_building.JPG" }
      ) {
        id
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return data
}
