import React from "react"
import GoogleMapReact from "google-map-react"

import Image from "react-bootstrap/Image"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

import Text from "components/text/text"
import Layout from "components/layout/layout"
import { Link } from "gatsby"

import Icon from "components/icon/icon"
import ICON_NAME_MAP from "icons/index"
import lockPath from "imgs/logo/lock.png"
import styles from "components/map/map.module.less"

const addLockCoords = {
  lat: 39.445205,
  lng: -84.408235,
}

const Marker = () => <Image src={lockPath} className={styles.marker} />

const MapContainer = props => (
  <>
    <Col sm={8}>
      <div
        className={styles.container}
        style={{ height: "50vh", width: "100%" }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_API_KEY }}
          defaultCenter={props.center || addLockCoords}
          defaultZoom={props.zoom || 14}
        >
          <Marker lat={addLockCoords.lat} lng={addLockCoords.lng} />
        </GoogleMapReact>
      </div>
    </Col>
    <Col sm={4} className={styles.address}>
      <Layout align="center">
        <Icon name={ICON_NAME_MAP.location} imgStyles={{ width: "30%" }} />
        <Layout direction="column">
          <Text size="title" weight="bold" style={{ margin: "10px 0px" }}>
            Add Lock Storage
          </Text>
          <Text>6227 Hamilton-Lebanon Rd. (S.R. 63)</Text>
          <Text>Middletown, OH 45044</Text>
        </Layout>
      </Layout>
      <Layout align="center">
        <Icon name={ICON_NAME_MAP.man} imgStyles={{ width: "30%" }} />
        <Layout direction="column">
          <Text size="title" weight="bold" style={{ margin: "10px 0px" }}>
            Mike Busemeyer
          </Text>
          <Text>(513)-539-5111</Text>
          <Link to="/contact" style={{ padding: "10px 0px" }}>
            <Button size="sm">Contact Mike</Button>
          </Link>
        </Layout>
      </Layout>
    </Col>
  </>
)

export default MapContainer

/*
have the "where to find us in bold"
have the contact information better set up
have a button going to the contact/form page
*/
