import React from "react"

import styles from "./layout.module.less"

const Layout = ({ direction, justify, align, children, style }) => {
  return (
    <div
      className={styles.layout}
      data-flex-direction={direction}
      data-justify={justify}
      data-align={align}
      style={style}
    >
      {children}
    </div>
  )
}

export default Layout
