import React from "react"
import uniqid from "uniqid"

import "bootstrap/dist/css/bootstrap.min.css"
import homepageStyles from "styles/homepage.module.less"

import PageLayout from "components/pageLayout"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Carousel from "react-bootstrap/Carousel"
import Button from "react-bootstrap/button"

import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import UnitCard from "components/unit/card/card"
import Text from "components/text/text"
import MapContainer from "components/map/container"

import Icon from "components/icon/icon"
import ICON_NAME_MAP from "icons/index"

import { Link } from "gatsby"
import Img from "gatsby-image"

import { useAllUnitsQuery, useHomepageQuery } from "../data/queries/index"

const iconClassNames = {
  img: homepageStyles.featureImg,
  gif: homepageStyles.featureGif,
}

const getIcon = type => {
  return <Icon shouldAnimate name={type} styleClassNames={iconClassNames} />
}

export default () => {
  const carouselData = useHomepageQuery()
  const unitsData = useAllUnitsQuery()

  const units = unitsData.allUnitsJson.edges.map(infoNode => {
    const photos = unitsData.allFile.edges.filter(
      ({ node }) => infoNode.node.id === node.relativeDirectory
    )

    const unitNode = { ...infoNode.node, images: [...photos] }

    return unitNode
  })

  const UnitSection = ({ numUnitsToShow }) => {
    const getUnits = () => {
      const showUnits = []
      let curIndex = 0

      while (showUnits.length < numUnitsToShow) {
        showUnits.push(units[curIndex])
        curIndex += 1
      }

      return showUnits
    }

    return (
      <>
        <Row
          className={`${homepageStyles.units} ${homepageStyles.unitSection}`}
        >
          {getUnits().map(unit => (
            <Col lg={5} md={5} sm={8} xs={8} key={uniqid()}>
              <UnitCard key={uniqid()} unit={unit} />
            </Col>
          ))}
        </Row>
        <Row>
          <Col
            className={`${homepageStyles.unitButton} ${homepageStyles.unitSection}`}
          >
            <Link to="/rates">
              <Button>
                See all units
                <KeyboardArrowRightIcon />
              </Button>
            </Link>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <PageLayout>
      <Carousel
        indicators={false}
        className={homepageStyles.carousel}
        interval={null}
      >
        <Carousel.Item className={homepageStyles.carouselItem}>
          <Img fluid={carouselData.sign.childImageSharp.fluid} />
        </Carousel.Item>
        <Carousel.Item className={homepageStyles.carouselItem}>
          <Img fluid={carouselData.office.childImageSharp.fluid} />
        </Carousel.Item>
        <Carousel.Item className={homepageStyles.carouselItem}>
          <Img fluid={carouselData.building.childImageSharp.fluid} />
        </Carousel.Item>
      </Carousel>
      <Container fluid>
        <UnitSection numUnitsToShow={3} />
        <Row className={homepageStyles.featureList}>
          <Col className={homepageStyles.feature} lg={4} md={4} xs={6}>
            {getIcon(ICON_NAME_MAP.car)}
            <Text>Drive-up units</Text>
          </Col>
          <Col className={homepageStyles.feature} lg={4} md={4} xs={6}>
            {getIcon(ICON_NAME_MAP.bell)}
            <Text>Individual unit alarms</Text>
          </Col>
          <Col className={homepageStyles.feature} lg={4} md={4} xs={6}>
            {getIcon(ICON_NAME_MAP.lightning)}
            <Text>Clean, well-lit units</Text>
          </Col>
          <Col
            className={homepageStyles.featureCamera}
            lg={{ span: 4, offset: 4 }}
            md={{ span: 4, offset: 4 }}
            xs={6}
          >
            {getIcon(ICON_NAME_MAP.eye)}
            <Text>Closed-circuit cameras</Text>
          </Col>
        </Row>
        <Row>
          <MapContainer />
        </Row>
      </Container>
    </PageLayout>
  )
}
